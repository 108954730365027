import React, { useEffect, useState, useCallback } from 'react';
import { Box, Heading, Input, Button, useToast, VStack, Spinner, Alert, AlertIcon, Text, FormControl, FormLabel, IconButton, Switch, HStack } from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowBackIcon, DeleteIcon, ArrowUpIcon, ArrowDownIcon } from '@chakra-ui/icons';
import axios from 'axios';

const OrganizationEditor = () => {
  const { code } = useParams();
  const [org, setOrg] = useState(null);
  const [name, setName] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [staff, setStaff] = useState([]);
  const [directors, setDirectors] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [newDepartment, setNewDepartment] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const toast = useToast();
  const navigate = useNavigate();

  const fetchOrg = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/org/${code}`);
      setOrg(response.data);
      setName(response.data.name);
      setCreatedAt(response.data.createdAt);
      setDirectors(response.data.directors || []);
      setDepartments(response.data.departments || []);
      if (response.data.staff && response.data.staff.length > 0) {
        const staffDetails = await Promise.all(response.data.staff.map(async member => {
          try {
            const userResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/${member.uid}`);
            return { ...member, ...userResponse.data };
          } catch (err) {
            console.error(`Failed to fetch details for user ${member.uid}:`, err);
            return { ...member, firstName: 'Unknown', lastName: 'Unknown', email: 'Unknown', profilePictureURL: '' };
          }
        }));
        setStaff(staffDetails);
      } else {
        setStaff([]);
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      setError('Failed to fetch organization: ' + err.message);
      setLoading(false);
    }
  }, [code]);

  useEffect(() => {
    fetchOrg();
  }, [fetchOrg]);

  const updateOrg = async () => {
    try {
      const updatedStaff = staff.map(member => ({
        uid: member.uid,
        firstName: member.firstName || '',
        lastName: member.lastName || '',
        email: member.email || '',
        profilePictureURL: member.profilePictureURL || '',
        verified: Boolean(member.verified),
        department: member.department || '',
        role: member.role || '',
        permissions: member.permissions || []  // Ensure permissions are preserved
      }));
  
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/edit-org/${code}`, { 
        name,
        staff: updatedStaff,
        directors,
        departments
      });
      toast({
        title: "Organization updated",
        description: "The organization details have been successfully updated.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate('/');
    } catch (err) {
      console.error(err);
      setError('Failed to update organization: ' + (err.response?.data?.details || err.message));
    }
  };
  

  const handleVerifiedChange = (index) => {
    const newStaff = [...staff];
    newStaff[index].verified = !newStaff[index].verified;
    setStaff(newStaff);
  };

  const addAsDirector = async (uid) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/add-director/${code}`, { uid });
      fetchOrg();
      toast({
        title: "Director added",
        description: "The user has been added as a director.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      console.error(err);
      setError('Failed to add director: ' + (err.response?.data?.details || err.message));
    }
  };

  const removeDirector = async (uid) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/remove-director/${code}`, { uid });
      fetchOrg();
      toast({
        title: "Director removed",
        description: "The user has been removed as a director.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      console.error(err);
      setError('Failed to remove director: ' + (err.response?.data?.details || err.message));
    }
  };

  const addDepartment = () => {
    if (newDepartment && !departments.includes(newDepartment)) {
      setDepartments([...departments, newDepartment]);
      setNewDepartment('');
    }
  };

  const removeDepartment = (dept) => {
    setDepartments(departments.filter(d => d !== dept));
  };

  const moveDepartmentUp = (index) => {
    if (index > 0) {
      const newDepartments = [...departments];
      const temp = newDepartments[index - 1];
      newDepartments[index - 1] = newDepartments[index];
      newDepartments[index] = temp;
      setDepartments(newDepartments);
    }
  };

  const moveDepartmentDown = (index) => {
    if (index < departments.length - 1) {
      const newDepartments = [...departments];
      const temp = newDepartments[index + 1];
      newDepartments[index + 1] = newDepartments[index];
      newDepartments[index] = temp;
      setDepartments(newDepartments);
    }
  };

  if (loading) {
    return (
      <Box textAlign="center" mt={5}>
        <Spinner size="xl" />
        <Text mt={4}>Loading organization details</Text>
      </Box>
    );
  }

  if (error) {
    return (
      <Alert status="error" mt={5}>
        <AlertIcon />
        {error}
      </Alert>
    );
  }

  return (
    <Box p={4} >
      <VStack spacing={4} align="stretch">
        <Box display="flex" alignItems="center">
          <IconButton
            icon={<ArrowBackIcon />}
            onClick={() => navigate('/')}
            aria-label="Go back"
            mr={2}
          />
          <Heading size="lg">Edit Organization</Heading>
        </Box>
        <Text>Organization Code: {org.code}</Text>
        <Text>Created At: {new Date(createdAt).toLocaleString()}</Text>
        <FormControl>
          <FormLabel>Organization Name</FormLabel>
          <Input
            placeholder="Organization Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            mb={4}
          />
        </FormControl>
        <Heading size="md">Staff</Heading>
        {staff.length > 0 ? (
          staff.map((member, index) => (
            <Box key={member.uid} borderWidth="1px" borderRadius="lg" p={4} mb={2}>
              <Text><strong>UID:</strong> {member.uid}</Text>
              <Text><strong>First Name:</strong> {member.firstName}</Text>
              <Text><strong>Last Name:</strong> {member.lastName}</Text>
              <Text><strong>Email:</strong> {member.email}</Text>
              <Text><strong>Profile Picture URL:</strong> {member.profilePictureURL}</Text>
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor={`verified-${member.uid}`} mb="0">
                <strong>Verified: </strong>
                </FormLabel>
                <Switch
                  id={`verified-${member.uid}`}
                  isChecked={member.verified}
                  onChange={() => handleVerifiedChange(index)}
                />
              </FormControl>
              <Button colorScheme="blue" size="xs" mt={2} onClick={() => addAsDirector(member.uid)}>
                Add as Director
              </Button>
            </Box>
          ))
        ) : (
          <Text>No staff</Text>
        )}
        <Heading size="md">Directors</Heading>
        {directors.length > 0 ? (
          directors.map((uid) => {
            const director = staff.find(member => member.uid === uid);
            return director ? (
              <Box key={uid} borderWidth="1px" borderRadius="lg" p={4} mb={2} display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                  <Text><strong>UID:</strong> {director.uid}</Text>
                  <Text><strong>First Name:</strong> {director.firstName}</Text>
                  <Text><strong>Last Name:</strong> {director.lastName}</Text>
                  <Text><strong>Email:</strong> {director.email}</Text>
                  <Text><strong>Profile Picture URL:</strong> {director.profilePictureURL}</Text>
                </Box>
                <IconButton
                  icon={<DeleteIcon />}
                  onClick={() => removeDirector(director.uid)}
                  aria-label="Remove director"
                  colorScheme="red"
                  size="xs"
                />
              </Box>
            ) : null;
          })
        ) : (
          <Text>No directors</Text>
        )}

        <Heading size="md">Departments</Heading>
        {departments.length > 0 ? (
          departments.map((dept, index) => (
            <HStack key={index} borderWidth="1px" borderRadius="lg" p={4} mb={2} justifyContent="space-between" alignItems="center">
              <Text>{dept}</Text>
              <Box>
                <IconButton
                  icon={<ArrowUpIcon />}
                  onClick={() => moveDepartmentUp(index)}
                  aria-label="Move department up"
                  size="xs"
                  mr={2}
                  isDisabled={index === 0}
                />
                <IconButton
                  icon={<ArrowDownIcon />}
                  onClick={() => moveDepartmentDown(index)}
                  aria-label="Move department down"
                  size="xs"
                  isDisabled={index === departments.length - 1}
                />
                <IconButton
                  icon={<DeleteIcon />}
                  onClick={() => removeDepartment(dept)}
                  aria-label="Remove department"
                  colorScheme="red"
                  size="xs"
                  ml={2}
                />
              </Box>
            </HStack>
          ))
        ) : (
          <Text>No departments</Text>
        )}
        <HStack>
          <Input
            placeholder="Add new department"
            value={newDepartment}
            onChange={(e) => setNewDepartment(e.target.value)}
          />
          <Button onClick={addDepartment} colorScheme="blue">Add Department</Button>
        </HStack>

        <Button onClick={updateOrg} colorScheme="blue" mt={4}>Save Changes</Button>
      </VStack>
    </Box>
  );
};

export default OrganizationEditor;
