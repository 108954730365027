import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import View from './components/View';
import OrganizationEditor from './components/OrganizationEditor';
import Admin from './components/Admin';
import { ChakraProvider, extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    heading: "Reddit Sans, sans-serif",
    body: "Reddit Sans, sans-serif",
  },
  colors: {
    blue: {
      400: "#0066c4",
      500: "#004381",
      600: '#001c36',
    },
  },
});


function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={
            <>
            <Admin/>
            <View />
            </>
          } />
          <Route path="/:code" element={<OrganizationEditor />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
