import React, { useEffect, useState, useCallback } from 'react';
import { Box, Heading, Text, VStack, Spinner, Alert, AlertIcon, Button, Input, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const View = () => {
  const [orgs, setOrgs] = useState([]);
  const [filteredOrgs, setFilteredOrgs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deletingOrg, setDeletingOrg] = useState(null);
  const [deleteConfirmCount, setDeleteConfirmCount] = useState(0);
  const [deleteConfirmName, setDeleteConfirmName] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    fetchOrgs();
  }, []);

  const handleSearch = useCallback(() => {
    const query = searchQuery.toLowerCase();
    const filtered = orgs.filter(org =>
      (org.name && org.name.toLowerCase().includes(query)) ||
      (org.code && org.code.toLowerCase().includes(query))
    );
    setFilteredOrgs(filtered);
  }, [searchQuery, orgs]);

  useEffect(() => {
    handleSearch();
  }, [searchQuery, orgs, handleSearch]);

  const fetchOrgs = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/orgs`);
      setOrgs(response.data);
      setFilteredOrgs(response.data);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch organizations');
      setLoading(false);
    }
  };

  const initiateDeleteOrg = (org) => {
    setDeletingOrg(org);
    setDeleteConfirmCount(0);
    setDeleteConfirmName('');
  };

  const confirmDeleteOrg = () => {
    if (deleteConfirmCount < 3) {
      setDeleteConfirmCount(deleteConfirmCount + 1);
    } else if (deleteConfirmName !== deletingOrg.name) {
      toast({
        title: "Deletion canceled",
        description: "The entered name doesn't match. Deletion canceled.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      setDeletingOrg(null);
      setDeleteConfirmCount(0);
      setDeleteConfirmName('');
    } else {
      deleteOrg(deletingOrg.id);
    }
  };

  const deleteOrg = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/delete-org/${id}`);
      fetchOrgs();
      toast({
        title: "Organization deleted",
        description: "The organization has been successfully deleted.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      setError('Failed to delete organization');
    } finally {
      setDeletingOrg(null);
      setDeleteConfirmCount(0);
      setDeleteConfirmName('');
    }
  };

  if (loading) {
    return (
      <Box textAlign="center" mt={5}>
        <Spinner size="xl" />
        <Text mt={4}>Loading organizations...</Text>
      </Box>
    );
  }

  if (error) {
    return (
      <Alert status="error" mt={5}>
        <AlertIcon />
        {error}
      </Alert>
    );
  }

  return (
    <VStack spacing={4} align="stretch" p={4}>
      <Heading size="lg">Organizations</Heading>
      <Input
        placeholder="Search by company code or name"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        mb={4}
      />
      {filteredOrgs.length > 0 ? (
        filteredOrgs.map(org => (
          <Box key={org.id} p={4} borderWidth={1} borderRadius="md">
            <Text size="md"><strong>Name:</strong> {org.name}</Text>
            <Text mt={2}><strong>Org Code:</strong> {org.code}</Text>
            <Text mt={2}><strong>Created at:</strong> {org.createdAt}</Text>
            <Button onClick={() => initiateDeleteOrg(org)} mt={2}>Delete</Button>
            <Button onClick={() => navigate(`/${org.code}`)} mt={2} ml={2}>Edit</Button>
          </Box>
        ))
      ) : (
        <Text>No organizations found</Text>
      )}

      <Modal isOpen={deletingOrg !== null} onClose={() => setDeletingOrg(null)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Organization</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {deleteConfirmCount < 3 ? (
              <Text>Are you sure you want to delete this organization? ({3 - deleteConfirmCount} more confirmations needed)</Text>
            ) : (
              <>
                <Text>Type the organization name to confirm deletion:</Text>
                <Input 
                  value={deleteConfirmName} 
                  onChange={(e) => setDeleteConfirmName(e.target.value)} 
                  placeholder="Organization name"
                  mt={2}
                />
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={confirmDeleteOrg}>
              Confirm
            </Button>
            <Button variant="ghost" onClick={() => setDeletingOrg(null)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default View;
