import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Heading,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';

const Admin = () => {
  const [orgName, setOrgName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/create-org`, { name: orgName });
      
      toast({
        title: 'Organization created.',
        description: `New org ID: ${response.data.orgId}`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      setOrgName('');
      
      setTimeout(() => {
        window.location.reload();
      }, 100);

    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to create organization.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error('Error creating organization:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box maxWidth="400px">
      <VStack spacing={4} align="stretch" p={4}>
        <Heading as="h1" size="xl">
          EvalHero Admin
        </Heading>
        <form onSubmit={handleSubmit}>
          <FormControl isRequired>
            <FormLabel>Organization Name</FormLabel>
            <Input
              type="text"
              value={orgName}
              onChange={(e) => setOrgName(e.target.value)}
              placeholder="Enter organization name"
            />
          </FormControl>
          <Button
            mt={4}
            colorScheme="blue"
            isLoading={isLoading}
            type="submit"
            width="full"
          >
            Create Organization
          </Button>
        </form>
      </VStack>
    </Box>
  );
};

export default Admin;
